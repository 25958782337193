import { createSlice } from '@reduxjs/toolkit';

const matchdaySlice = createSlice({
  name: 'matchday',
  initialState: {
    eventData: {},
    loadingEventData: true,
    loadingPlayers: true,
    loadingTimeline: true,
    players: [],
    timeline: [],
    motmVotes: [],
    rateVotes: [],
    eventVote: {},
  },
  reducers: {
    setEventData(state, action) {
      state.eventData = action.payload.eventData;
      state.loadingEventData = true;
    },
    setPlayers(state, action) {
      state.players = action.payload.players;
      state.loadingPlayers = false;
    },
    setTimeline(state, action) {
      state.timeline = action.payload.timeline;
      state.loadingTimeline = false;
    },
    setMotmVotes(state, action) {
      state.motmVotes = action.payload.motmVotes;
    },
    setRateVotes(state, action) {
      state.rateVotes = action.payload.rateVotes;
    },
    setEventVote(state, action) {
      state.eventVote = action.payload.eventVote;
    },
    resetState(state) {
      state.eventData = {};
      state.loadingEventData = true;
      state.loadingPlayers = true;
      state.loadingTimeline = true;
      state.players = [];
      state.timeline = [];
      state.motmVotes = [];
      state.rateVotes = [];
      state.eventVote = {};
    },
  },
});

export const { setPlayers, resetState, setMotmVotes, setRateVotes, setTimeline, setEventVote, setEventData } = matchdaySlice.actions;

export default matchdaySlice.reducer;
