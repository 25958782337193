import styled from "@emotion/styled";
import { useState, useEffect, useMemo, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { BREAKPOINTS } from "../configs/consts";
import ToggleSlider from "../Molecules/ToggleSlider";
import ToolHelper from "../utils/ToolHelper";
import useOutsideAction from "../utils/useOutsideAction";
import CustomLink from "../Molecules/CustomLink";
import CoreLink from "../Molecules/CoreLink";
import { Bell } from "react-feather";
import Txt from "../Atoms/Txt";

import { useWindowSize } from '../utils/useWindowSize';
import ticket from '../../assets/ticket.png';
import bag from '../../assets/bag.png';
import { ScreenPropsContext } from '../../context/ScreenPropsContext.js';
import SocioButton from '../Atoms/SocioButton.js';
import Image from '../Molecules/Image.js';
import Button from '../Atoms/Button.js';
import { TypographyContext } from '../../context/TypographyContext.js';

import { FiPlus, FiMinus } from "react-icons/fi";
import { LiaExternalLinkSquareAltSolid } from "react-icons/lia";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";
import CruzeiroApi from "../utils/CruzeiroApi.js";
// import Button from '../Atoms/Button'

const HeaderDropMenuItem = (props) => {
  const { config, linkProps, checkboxData, openGlobalPopup, targetPopup, hideOn = [], secondary, rightPanel, dataHelper, menu, handleOpenSubMenus, toggleIsOpen } = props;
  const { customComponent, label } = linkProps;
  const className = useMemo(() => hideOn.join(" "), [hideOn])

  const closeMenu = () => {
    toggleIsOpen(false);
  }

  const openPopup = (e) => {
    e.preventDefault();
    openGlobalPopup(targetPopup)
  }

  const openSubMenu = () => {
    handleOpenSubMenus(menu.id);
  }

  const DropMenuLink = () => {
    if (checkboxData) {
      return <>
        <span className={'label'} config={config}>{label}</span>
        <ToggleSlider config={config} isChecked={checkboxData.isChecked} onToggle={checkboxData.onToggle} />
      </>
    }

    if (customComponent) {
      return <CustomLink {...props} componentName={customComponent} />
    }

    if (targetPopup) {
      return <Link
        className={'label ' + (secondary ? 'secondary' : '')}
        to={'/'}
        onClick={openPopup}
        config={config}
      >
        {label}
      </Link>
    }

    // if (isButton) {
    //   return <Button className={'label ' + (secondary ? 'secondary' : '')} onClick={handleButtonClick} key={label} size={`${label.length}ch`} textAlign={'left'}>{label}</Button>
    // } 

    return <>
      {!secondary && menu.items.length > 0 ?
        <>
          <div onClick={openSubMenu}>
            <CoreLink
              {...linkProps}
              config={config}
              menu={menu}
              toggleMenu={closeMenu}
              className={'label ' + (secondary ? 'secondary' : '')}
            >
              <Txt className="main-link" typo="subtitle-xl">{label}</Txt>
            </CoreLink>
          </div>
          <div className="collpse-menu-action" onClick={openSubMenu}>
              {menu.showItems ?
                <FiMinus />
                :
                <FiPlus />
              }
          </div>
        </>            
      :
        <>
          <CoreLink
            {...linkProps}
            config={config}
            menu={menu}
            toggleMenu={closeMenu}
            className={'label ' + (secondary ? 'secondary' : '')}
          >
            { secondary ?
              <>
                <Txt typo="paragraph-md" typoMobile="paragraph-xl">{label}</Txt>
                {linkProps.target === '_blank'
                  ?
                  <div className="external-button">
                    <LiaExternalLinkSquareAltSolid />
                  </div>
                  :
                  <></>
                }
                
              </>
            :
              <Txt typo="heading-sm" typoMobile="heading-sm">{label}</Txt>
            }
            
          </CoreLink>
        </>
      }
    </>
  }

  return <StyledHeaderDropMenuItem className={className} config={config} data={props.data}>
    <DropMenuLink />
  </StyledHeaderDropMenuItem>;
}

const HeaderDropMenu = (props) => {
  const { isOpen, toggleIsOpen, menuItems, openGlobalPopup, data, rightPanel, dataHelper, handleOpenSubMenus, adjustMenuPosition } = props;
  const { width: widthWindow } = useWindowSize();
  const isMobile = widthWindow <= BREAKPOINTS.PHONE;

  // const categories = [
  //   { label: "masculino", hide: false },
  //   { label: "feminino", hide: false },
  //   { label: "base", hide: true },
  // ];

  const closeMobileMenu = () => {
    toggleIsOpen(false);
  }

  let config = useMemo(() => ToolHelper.getThemedConfig(props, 'HeaderDropMenu.preset'), [props]);

  const openSubMenu = (menuId) => {
    handleOpenSubMenus(menuId);
  }

  const SocioTags = (props) => {
    const { loyaltyItems } = props;
    const screenProps = useContext(ScreenPropsContext);
    const [loading, setLoading] = useState(false);
    const { config, userHelper, routes, history, dataHelper } = screenProps;

    const { disableLogin } = useSelector((state) => state.membership);

    const handleIngressoButton = async () => {
      const user = await userHelper.getUser();
      if (!disableLogin && (!user || Object.keys(user).length === 0)) {
        history.push(routes.login.path, {
          redirectUrl: routes.socioSSO.path,
        });
        return;
      }
      const newWindow = window.open('about:blank', '_blank');

      try {
        if (loading) return;
  
        setLoading(true);
        const { success, login_url } = await CruzeiroApi.loginTicketSSO(
          user?.FutebolCardCPF__c,
          user?.FutebolCardEmail__c,
          dataHelper
        );
  
        if (success) {
          return newWindow.location.href = login_url;
        }

        newWindow.location.href = "https://cruzeiro.futebolcard.com";
      } catch (error) {
        newWindow.location.href = "https://cruzeiro.futebolcard.com";
      } finally {
        setLoading(false);
      }
    };
  
    return <StyledSocioTags>
      <TypographyContext.Provider value="tag-button-xl">
        {loyaltyItems?.length ? loyaltyItems.map(item => (
          <div key={item.Id} className='loyalty-item'>
            <Image src={item.ImageURL__c} />
            <Txt typo='tag-button-xl'>{item.Label__c}</Txt>
            <Txt typo='tag-button-xl' className='short-description' color={config?.colors?.BRAND.GOLD}>{item.ShortDescription__c}</Txt>
          </div>
        )) : (
          <>
            <div className="top-buttons">
              <div>
                <Button
                  className="loyalty-item"
                  onClick={handleIngressoButton}
                  target="_blank"
                >
                  <Image src={ticket} />
                  <Txt typo="tag-button-xl">Ingressos</Txt>
                </Button>
              </div>
              <div className="bottom-button">
                <Button
                  className="loyalty-item"
                  href="https://loja.cruzeiro.com.br/"
                  target="_blank"
                >
                  <Image src={bag} />
                  <Txt typo="tag-button-xl">Loja</Txt>
                </Button>
              </div>
            </div>
          </>
        )}
        <SocioButton />
      </TypographyContext.Provider>
    </StyledSocioTags>
  }

  const SocialMedias = (props) => {
    const { config } = props;
    const { socialMedias } = useSelector((state) => state.socialMedias);
    const socialMediasF = useMemo(() => socialMedias['Masc'],[socialMedias]);

    if(!socialMediasF?.length) {
      return <></>;
    }

    return <SocialMediaButtons config={config}>
      <div id="fixedElement" className="social-media">
        {socialMediasF?.length ?
        socialMediasF.map((item) => (
          <a key={item.URL__c} target="_blank" href={item.URL__c} rel="noreferrer">
            <Image src={item.Image__c} />
          </a>
        ))
        : 
        <></>}
       </div>
    </SocialMediaButtons>;
  }

  return <>
    <StyledHeaderDropMenuV2 config={config} isOpen={isOpen} adjustMenuPosition={adjustMenuPosition}>
      {
        isMobile ?
        <>
          <div className="close-menu" onClick={closeMobileMenu}>
            <IoClose />
          </div>
          <SocioTags/>
        </>
        :
        <></>
      }
      <div className="container-menu-items">
      <ul>
        <div className={'main-items'}>
          {/* <div>
            {categories.map(
              (category, index) =>
                !category?.hide && (
                  <div key={index}>
                    <Link
                      className="label"
                      to={`/categoria/${category?.label}`}
                    >
                      <Txt className="main-link" typo="subtitle-xl">
                        {category?.label}
                      </Txt>
                    </Link>
                  </div>
                )
            )}
          </div> */}
          {menuItems?.map(menuItem =>
            <div key={menuItem.id}>
              {menuItem.showContent ?
              <div key={menuItem.id}>
                <HeaderDropMenuItem toggleIsOpen={toggleIsOpen} handleOpenSubMenus={openSubMenu} menu={menuItem} secondary={false} linkProps={menuItem} config={config} data={data} openGlobalPopup={openGlobalPopup} rightPanel={rightPanel} dataHelper={dataHelper} />
                <div>
                  {menuItem.showItems ? 
                    menuItem.items?.map(item => 
                      <HeaderDropMenuItem toggleIsOpen={toggleIsOpen} key={item.id} secondary={true} linkProps={item} config={config} data={data} openGlobalPopup={openGlobalPopup} rightPanel={rightPanel} dataHelper={dataHelper} />
                    )
                  :
                    null
                  }
                </div>
              </div>
              : null}
            </div>
          )}
        </div>
      </ul>
      
      </div>
    </StyledHeaderDropMenuV2>
    {
      isMobile && isOpen ?
        <SocialMedias config={props.config} />
      :
      <></>
    }
  </>
}

export default HeaderDropMenu;

/* style */
const SocialMediaButtons = styled.div`
    .social-media {
      background-color: ${props => props.config?.colors?.BRAND.DARK};
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      color: rgba(255, 255, 255, .64);
      height: 72px;
      width: 100%;
      bottom: 0px;
      position: fixed;
      left: 0;
      z-index: 10000;
    }

    img {
      height: 30px;
    }
  }
`

const StyledSocioTags = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 30px;

  .top-buttons {
    display: flex;
    justify-content: space-between;

    div {
      width: 48%;
      background-color: rgba(255 255 255 / 8%);
      padding: 8px 0px 8px 0px;
      border-radius: 4px;
    }
  }

  .loyalty-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .short-description{
      @media (max-width: ${BREAKPOINTS.PHONE_BIGGER}px) {
        display: none;
      }
    }

    img{
      height: 24px;
      margin-right: 8px;
    }
  }
`

const StyledHeaderDropMenuV2 = styled.div`
  width: 100%;
  max-width: 416px;
  position: fixed;
  bottom: 0;
  left: 0;
  top: ${ props => !props.adjustMenuPosition ? 'none' : '87px'};
  height: ${ props => !props.adjustMenuPosition ? 'calc(100vh - 133px)' : 'calc(100vh - 87px)'};
  transition: transform 100ms linear;
  transform: translate(${props => props.isOpen ? '0' : '-100%'},0%);
  background-color: ${props => props.config?.colors?.BRAND.DARK};
  border-top: 1px solid ${props => props.config?.colors?.WHITE.W16};
  padding: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);

  @media (max-width:${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    top: ${ props => !props.adjustMenuPosition ? '193px' : '148px'};
    height: ${props => props.adjustMenuPosition ? 'calc(100vh - 148px)' : 'calc(100vh - 193px)'};
  }

  @media (max-width:${1020}px) {
    top: ${ props => !props.adjustMenuPosition ? '200px' : '148px'};
    height: ${props => props.adjustMenuPosition ? 'calc(100vh - 145px)' : 'calc(100vh - 193px)'};
  }

  @media (max-width:${BREAKPOINTS.TABLET_PORTRAIT}px) {
    top: ${ props => !props.adjustMenuPosition ? '175px' : '130px'};
    height: ${props => props.adjustMenuPosition ? 'calc(100vh - 130px)' : 'calc(100vh - 193px)'};
  }

  .socio-button {
    height: 42px;
  }

  .socio-button span {
    font-size: 16px !important;
    line-height: 18px !important;
    font-family: TekturNarrow;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .04em;
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    width: 100%;
    max-width: 100%;
    height: 100%;
    left: 0;
    padding: 2em 1.5em 0 1.5em;
    top: 0;

    .main-items {
      margin-bottom: 72px;
    }
  }

  .main-link {
    cursor: pointer;
  }

  .close-menu {
    color: #fff;
    font-size: 34px;
    font-weight: 900;
    cursor: pointer;
    margin-top: 45px;
    margin-bottom: 30px;
  }

  .label{
    display: flex;
    justify-content: space-between;
    padding: 12px 0px 12px 0px;
    font-size: 1.3em;
    font-weight: 600;
    text-transform: uppercase;
    color: ${props => props.config?.themeLayout?.title};
    cursor: initial;
  }

  a.label{
    width: 100%;
    color: ${props => props.config?.colors?.WHITE.W};
    cursor: pointer;

    span{
      display: inline-block;
    }

    &.secondary{
      color: rgba(255, 255, 255, 0.64);
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    }

    &.secondary span{
      text-transform: none;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px){    
    &.mobile {
      display: none;
    }
  }
    
  @media (min-width:${BREAKPOINTS.PHONE + 1}px) and (max-width:${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    &.tablet {
      display: none;
    }
  }

  @media (min-width:${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    &.desktop {
      display: none;
    }
  } 
`

const StyledHeaderDropMenu = styled.div`
  position: absolute;
  background: ${props => props.config?.themeLayout?.background || 'transparent'};
  right: 0;
  padding: 20px;
  border-bottom-left-radius: ${props => (props.config?.layout?.BOX_BORDER_BOTTOM_LEFT_RADIUS || 0) * 2}px;
  border-bottom-right-radius: ${props => (props.config?.layout?.BOX_BORDER_BOTTOM_RIGHT_RADIUS || 0) * 2}px;
  border-bottom: 4px solid rgba(0,0,0,0.2);
  z-index: 9999;
  overflow: hidden;
  transition: transform 200ms linear;
  transform-origin: top;
  transform: scaleY(${props => props.isOpen ? 1 : 0});

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    width: 100%;
    left: 0;
  }

  ul{
    display: flex;

    .mobile-items{
      display: none;

      @media (max-width: ${BREAKPOINTS.PHONE}px){
        display: block;
      }
    }
  }
`;

const StyledHeaderDropMenuItem = styled.li`
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${BREAKPOINTS.PHONE}px) {
    padding-right: 0px
  }

  .external-button {
    font-size: 20px;
    font-weight: 400;
    line-height: 18px;
  }

  .collpse-menu-action {
    color: ${props => props.config?.colors?.BRAND.GOLD};
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
  }

  .label{
    display: flex;
    justify-content: space-between;
    padding: 12px 0px 12px 0px;
    font-size: 1.3em;
    font-weight: 600;
    text-transform: uppercase;
    color: ${props => props.config?.themeLayout?.title};
    cursor: initial;
  }

  a.label{
    width: 100%;
    color: ${props => props.config?.colors?.WHITE.W};
    cursor: pointer;

    span{
      display: inline-block;
    }

    &.secondary{
      color: rgba(255, 255, 255, 0.64);
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    }

    &.secondary span{
      text-transform: none;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: ${BREAKPOINTS.PHONE}px){    
    &.mobile {
      display: none;
    }
  }
    
  @media (min-width:${BREAKPOINTS.PHONE + 1}px) and (max-width:${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    &.tablet {
      display: none;
    }
  }

  @media (min-width:${BREAKPOINTS.TABLET_LANDSCAPE}px) {
    &.desktop {
      display: none;
    }
  } 
`
