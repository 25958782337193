import { configureStore } from '@reduxjs/toolkit'

import newsReducer from './news';
import bannersReducer from './banners';
import eventsReducer from './events';
import audienceTypesReducer from './audienceTypes';
import socialMediasReducer from './socialMedias';
import membershipReducer from './membership';
import userDataReducer from './userData';
import loyaltyReducer from './loyalty';
import matchdayReducer from './matchday'

const store = configureStore({
  reducer: {
    news: newsReducer,
    events: eventsReducer,
    audienceTypes: audienceTypesReducer,
    banners: bannersReducer,
    socialMedias: socialMediasReducer,
    membership: membershipReducer,
    userData: userDataReducer,
    loyalty: loyaltyReducer,
    matchday: matchdayReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})

export { store }
