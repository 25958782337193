import { collection, getDoc, getDocs, doc, query, where, onSnapshot, addDoc, setDoc, serverTimestamp, orderBy, limit, startAfter, writeBatch, updateDoc } from 'firebase/firestore';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider, EmailAuthProvider, signInWithPopup, signOut, sendPasswordResetEmail, fetchSignInMethodsForEmail, reauthenticateWithCredential, updatePassword, deleteUser, signInWithCustomToken, signInWithCredential, linkWithCredential, linkWithPopup, signInWithRedirect } from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';
import moment from 'moment';

import GameficationHelper from './GameficationHelper';
import { POINT_ACTIONS, FAKE_DOMAIN, DEFAULT_LOYALTY_CATEGORY } from './consts';
import { HEROKU_AUTHORIZATION, HEROKU_LOYALTY_URL, HEROKU_CASE_URL, HEROKU_GENERATE_ACCESS_CODE, HEROKU_CHECK_ACCESS_CODE, HEROKU_VALIDATE_ACCESS_CODE, HEROKU_CASE_WITH_FILE_URL } from './URLconsts';
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import FormHelper from './FormHelper';
import ToolHelper from './ToolHelper';
import CruzeiroApi from './CruzeiroApi';
import { COLLECTIONS } from './consts';

export default class UserHelper {
  constructor(app, db, getConfigFile) {
    this.app = app;
    this.db = db;

    getConfigFile(`utils/UserHelper/config.js`).then((importedFile) => {
      let customCfgClass = importedFile?.default;

      if (customCfgClass && typeof customCfgClass === 'function') {
        this.customCfg = new customCfgClass();
      }
    });
    global.dbForCEP = this.db;
  }

  setDataHelper(dataHelper) {
    this.dataHelper = dataHelper;
  }

  async getHerokuUsernameDoc(username, include_userfbid = 1) {
    if (!username) {
      return { success: false };
    }

    let data = {
      username,
      include_userfbid,
    }

    var herokuDomain = await this.dataHelper.getHerokuDomain();
    var getUsernameUrl = herokuDomain + '/getUsername';

    try {
      let response = await fetch(getUsernameUrl + '?' + new URLSearchParams(data), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
      });

      if (response.ok) {
        let result = await response.json();
        return result;
      }
    }
    catch (e) {
    }

    return { success: false };
  }

  async findUserFirebaseDoc(userData) {
    if (!userData?.username && !userData?.userFirebaseId) {
      return false;
    }

    let { username, userFirebaseId } = userData;

    if (userFirebaseId) {
      let userFirebaseQ = doc(this.db, 'UserFirebaseId', userFirebaseId);
      let userFirebaseDoc = await getDoc(userFirebaseQ);

      if (userFirebaseDoc?.exists()) {
        return userFirebaseDoc.data();
      }
    }
    else {
      let userFirebaseQ = query(collection(this.db, 'UserFirebaseId'), where('Email', '==', username));
      let userFirebaseDocs = await getDocs(userFirebaseQ);

      if (userFirebaseDocs.size > 0) {
        if(userFirebaseDocs.size > 1){
          console.log('ERROR - multiple userFirebaseId records for this user was found!')
        }

        let docData;
        userFirebaseDocs.forEach((doc) => {
          docData = doc.data();
        })

        return docData;
      }
    }

    return false;
  }

  async postSignInProcess(userCredential) {
    let user = userCredential.user;
    let userData = await this.getUserDataByEmail(user.email);
    let hasUserDoc = !!userData;

    if (userData?.acg_ProgramOptOut__c) {
      await this.logoutAsync();

      return {
        success: false,
        error: {
          code: 'auth/user-disabled',
        }
      }
    }

    return {
      success: true,
      user: userCredential,
      hasUserDoc,
    };
  }

  async getUserGroup(userData) {
    if (!userData?.FirebaseId__c) {
      return {};
    }

    let userGroupQ = query(collection(this.db, 'acg_UserGroup'), where('acg_MainUser__r.FirebaseId__c', '==', userData.FirebaseId__c));
    let userGroupDocs = await getDocs(userGroupQ);
    let firebaseData = {};

    if (userGroupDocs.size > 0) {
      userGroupDocs.forEach((doc) => {
        firebaseData = doc.data();
      })
    }

    return firebaseData;
  }

  // Dado um grupo de usuário, retorna qual conta deve ser logada
  // Isso pode variar pois o último usuário logado pode estar desabilitado, neste caso é necessário escolher outro para fazer o login e só quem pode fazer essa decisão é o heroku.
  async switchToDefaultUser(params) {
    let { mainFirebaseId } = params;
    let accessCode = this.generateRandomAccessCode(10);

    const userRef = await this.getUserRef();

    await setDoc(userRef, {
      switchUserGroupAccessCode: accessCode,
    }, { merge: true });

    var herokuDomain = await this.dataHelper.getHerokuDomain();
    var switchToDefaultUserUrl = herokuDomain + '/switchToDefaultUser';

    var herokuData = {
      mainFirebaseId,
      accessCode,
    }

    var result = {
      success: false,
      error: 'unknown-error',
    }

    try {
      var response = await fetch(switchToDefaultUserUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(herokuData),
      });

      if (response.ok) {
        result = await response.json();

        if (result.success && result.url) {
          document.location.href = result.url;
        }
      }
    }
    catch (e) {
      console.log('err', e);
    }

    return result;

  }

  async loginUserGroup(userGroup, userData) {
    // É necessário trocar o login se o MainUser não foi o último logado, ou se o MainUser está desabilitado
    if (
      (userGroup?.lastLoggedFirebaseId && userGroup?.lastLoggedFirebaseId !== userData.FirebaseId__c) ||
      userData?.acg_ProgramOptOut__c
    ) {
      return await this.switchToDefaultUser({
        mainFirebaseId: userGroup.acg_MainUser__r.FirebaseId__c,
      })
    }

    return {
      success: true,
    };
  }

  async switchUserGroupLogin(params) {
    let { mainFirebaseId, fromFirebaseId, toFirebaseId } = params;

    let accessCode = this.generateRandomAccessCode(10);

    const userRef = await this.getUserRef();

    await setDoc(userRef, {
      switchUserGroupAccessCode: accessCode,
    }, { merge: true });

    var herokuDomain = await this.dataHelper.getHerokuDomain();
    var sendSwitchUserGroup = herokuDomain + '/switchUserGroupLogin';

    var herokuData = {
      mainFirebaseId,
      fromFirebaseId,
      toFirebaseId,
      accessCode,
    }

    var result = {
      success: false,
      error: 'unknown-error',
    }

    try {
      var response = await fetch(sendSwitchUserGroup, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(herokuData),
      });

      if (response.ok) {
        result = await response.json();

        if (result.success && result.url) {
          document.location.href = result.url;
        }
      }
    }
    catch (e) {
      console.log('err', e);
    }

    return result;
  }

  async userLogin(username, password) {
    const auth = getAuth();

    let foundUserFirebaseDoc = await this.findUserFirebaseDoc({ username });

    if (foundUserFirebaseDoc?.Email) {
      try {
        let userCredential = await signInWithEmailAndPassword(auth, username, password)
        const response = await this.postSignInProcess(userCredential);

        return response;
      }
      catch (error) {
        return {
          success: false,
          error,
        }
      }
    }

    return {
      success: false,
      error: {
        code: 'auth/user-not-found'
      }
    }
  }

  async userLoginWithToken(token) {
    await this.logoutAsync();
    const auth = getAuth();

    try {
      let userCredential = await signInWithCustomToken(auth, token);
      return await this.postSignInProcess(userCredential);
    }
    catch (error) {
      return {
        success: false,
        error,
      }
    }
  }

  async userLoginByFirebaseId(userFirebaseId, password) {
    let userDoc = await this.findUserFirebaseDoc({ userFirebaseId });

    if (userDoc?.Email) {
      return this.userLogin(userDoc.Email, password);
    }

    return {
      success: false,
      error: {
        code: 'auth/user-not-found'
      }
    }
  }

  getAuthUser() {
    const auth = getAuth();
    return auth?.currentUser;
  }

  async getUserFirebaseId() {
    let user = this.getAuthUser();

    if (!user)
      return null;

    if (!global.userFirebaseId) {
      const userEmail = user?.email || user?.providerData[0]?.email || null;

      if (!userEmail) {
        return null;
      }

      let userData = await this.getUserDataByEmail(userEmail);

      if (userData?.FirebaseId__c) {
        global.userFirebaseId = userData.FirebaseId__c;
        return global.userFirebaseId;
      }

      return null;
    } else {
      return global.userFirebaseId;
    }
  }

  async getUserDataByEmail(email) {
    if (email?.length) {
      email = email.toLowerCase();
    }

    let userFirebaseId;
    let userData;

    if (global.userFirebaseId) {
      userFirebaseId = global.userFirebaseId;
    }
    else {
      let ufbresult = await this.findUserFirebaseDoc({ username: email });
      if (ufbresult?.UserFirebaseId) {
        global.userFirebaseId = userFirebaseId = ufbresult.UserFirebaseId
      }
    }

    if (userFirebaseId) {
      try {
        let userRef = doc(this.db, 'Users', userFirebaseId);
        userData = (await getDoc(userRef)).data();
      }
      catch (e) {
        console.log('request denied [Users]',e)
        return await this.deniedFirestoreRequest(e);
      }
    }

    return userData;
  }

  async deniedFirestoreRequest(e) {
    if (e?.code === 'permission-denied') {
      await this.logoutAsync()
      return false;
    }

    return false;
  }

  async getUserRef() {
    let userFirebaseId = await this.getUserFirebaseId();
    if (!userFirebaseId) return null;

    return doc(this.db, 'Users', userFirebaseId);
  }

  async getUserFirebaseIdRef() {
    let userFirebaseId = await this.getUserFirebaseId();
    if (!userFirebaseId) return null;

    return doc(this.db, 'UserFirebaseId', userFirebaseId);
  }

  async getUser() {
    let userRef = await this.getUserRef();

    if (userRef) {
      try {
        let userDoc = await getDoc(userRef);
        // validação parcial
        if (userDoc.exists()) {
          const userDocData = userDoc.data();
          const userData = {
            ...userDocData,
            CPF__c: userDocData?.CPF__c || userDocData['CPF__c'],
            EmailIsReal: !!(userDocData.Email?.length) || false
          }
          return userData;
        }
      }
      catch (e) {
        return await this.deniedFirestoreRequest(e);
      }
    }

    return null;
  }

  async snapUser(callback) {
    let userRef = await this.getUserRef();

    if (userRef) {
      let unsub = onSnapshot(userRef, async (snapshot) => {
        if (snapshot?.id) {
          let userData = snapshot.data()
          let groupData;

          // Buscando grupo
          if (userData?.acg_UserGroup__c) {
            let groupId = userData.acg_UserGroup__c.slice(0, 15);
            let groupDoc = await getDoc(doc(this.db, 'acg_UserGroup', groupId));

            if (groupDoc.exists()) {
              groupData = groupDoc.data();
            }
          }

          callback({
            ...userData,
            UserFirebaseId: snapshot.id,
            groupData,
          });
        }
        else {
          callback({})
        }
      })

      let t = {};
      t['user' + new Date().getTime()] = unsub;

      return t;
    }

    return null;
  }

  // userType é o que possivelmente redefine as configurações e layouts do usuário. 
  // Chama customCfg pois isso pode variar de projeto para projeto.
  async setUserType(user) {
    let hasSetUserType = false;

    if (this.customCfg?.setUserType) {
      let result = await this.customCfg.setUserType(user, { userHelper: this });

      if (result.userType) {
        global.userType = result.userType;
        hasSetUserType = true;
      }

      if (result.userTypes) {
        global.userTypes = result.userTypes;
      }

      if (result.user) {
        user = result.user;
      }
    }

    if (!hasSetUserType) {
      global.userType = user.MilestoneCategory__c;
      global.userTypes = [user.MilestoneCategory__c];
    }
  }

  getMessaging() {
    if (!this.messaging)
      this.messaging = getMessaging(this.app);

    return this.messaging;
  }

  async getNotificationTopics() {
    let topicsQ = query(collection(this.db, 'NotificationTopic'), orderBy('Order__c'));
    let topicsDoc = await getDocs(topicsQ);

    let data = [];

    if (topicsDoc.docs.length) {
      data = topicsDoc.docs.every((doc) => {
        let docData = doc.data();

        if (docData.FirebaseId__c != null && docData.FirebaseId__c != '') {
          data.push(docData);
        }

        return true;
      })
    }

    return data;
  }

  getMessagingToken(callback) {
    this.getMessaging();

    getToken(this.messaging, { vapidKey: 'BKAR7vdFOwdG7QEzni0nFTAwFg2W03FfPYqyRhGGX1gVOhandpZ6gbrupH5ZOF0kmMSD9ExI3LledLgPmFQsPUE' }).then(async (currentToken) => {
      const userFirebaseId = await this.getUserFirebaseId();

      let ref = collection(this.db, 'Users', userFirebaseId, 'NotificationTokens');

      await addDoc(ref, {
        messagingToken: currentToken,
        messagingTokenTimestamp: serverTimestamp()
      });

      // let allTopics = await this.getNotificationTopics();
      // TODO - O registro de token em um tópico não pode ser feito pelo firebase web. É necessário criarmos um acesso no Heroku para encaminhar esta requisição.

      callback({ currentToken });
    }).catch((err) => {
      callback({ err });
    });
  }

  async authEmailExists(email) {
    const auth = getAuth();
    const result = await fetchSignInMethodsForEmail(auth, email)

    return result.length > 0;
  }

  async firestoreEmailExists(email) {
    let userData = await this.getUserDataByEmail(email);
    return userData?.Email?.length;
  }

  async cpfExists(cpf) {
    let cleanCpf = cpf.replace(/\D/g, '');

    let user = await this.getUser();
    if (user?.CPF__c && user?.CPF__c === cleanCpf) {
      return false;
    }

    let cpfQ = doc(this.db, 'CPF', cleanCpf);
    let cpfDoc = await getDoc(cpfQ);

    return cpfDoc.exists();
  }

  handlePromoCode = async (promoCode) => {
    let promoCodeQ = query(collection(this.db, 'Users'), where('ReferralCode__c', '==', promoCode));
    let promoCodeDocs = await getDocs(promoCodeQ)

    if (promoCodeDocs.size > 0) {
      promoCodeDocs.forEach((doc) => {
        this.redeemGenerator = doc.data();
      })

      return true;
    }

    return false;
  }

  editUserData = async (userData, userCredential, originalUserData) => {
    let userUID;
    if (userData?.UID) {
      userUID = userData.UID;
    }
    else if (userCredential?.uid) {
      userUID = userCredential.uid;
    }
    else {
      return {
        success: false,
        error: {
          code: 'no-uid',
        }
      }
    }

    const Topics__c = this.subscribeAllTopics();

    let userId = await this.getUserFirebaseId();
    let saveType = userId ? 'update' : 'create';

    let firebaseIdDoc;
    let firebaseIdGetDoc;

    if (saveType === 'update') {
      firebaseIdDoc = doc(this.db, 'UserFirebaseId', userId);
      firebaseIdGetDoc = await getDoc(firebaseIdDoc);
    }

    if (saveType === 'create' || !firebaseIdGetDoc.exists()) {
      // Criando UserFirebaseId
      firebaseIdDoc = await addDoc(collection(this.db, 'UserFirebaseId'), {
        UID: userUID,
        Email: userData.AuthRegisterEmail,
      })

      // Setando categoria padrão
      userData.LoyaltyCategory__c = userData.LoyaltyCategory__c || DEFAULT_LOYALTY_CATEGORY;
    }
    
    // Atualizando doc CPF
    if (userData?.CPF__c){
      let cleanCpf = userData?.CPF__c?.replace(/\D/g, '');

      if(saveType === 'create' || cleanCpf !== originalUserData?.CPF__c){
        let cpfQ = doc(this.db, 'CPF', cleanCpf);
  
        await setDoc(cpfQ, {
          Email: userData.Email || '',
          CPF: cleanCpf,
          UserFirebaseId: firebaseIdDoc.id,
        })
  
        // reseta CPFStatus
        userData.CPFStatus = null;
      }
    }

    // Atualizando doc UserFirebase
    let firebaseIdData = {
      UserFirebaseId: firebaseIdDoc.id,
    };

    if (userData.UserFirebaseIdData) {
      firebaseIdData = {
        ...firebaseIdData,
        ...userData.UserFirebaseIdData
      }
    }

    await setDoc(firebaseIdDoc, firebaseIdData, { merge: true })

    // Atualizando doc User
    userData.UID = userUID;
    userData.FirebaseId__c = firebaseIdDoc.id;
    userData.MilestoneCategory__c = 'Parceiro';

    await this.setUserData(userData)

    // Criando registros de loyalty
    try {
      await this.updateLoyalty();
    } catch (e) {
      console.log('updateLoyalty fail', e);
    }


    if (this.redeemGenerator) {
      // Contact Action para o usuário que compartilhou o código
      await GameficationHelper.addRow(this, POINT_ACTIONS.REFER_SENDER, null, null, null, null, null, null, null, null, null, null, this.redeemGenerator, null, null, null);
    }

    return {
      success: true,
      user: userData,
    }
  }

  insupdateUsernames = async (firebaseId, originalUserData, userData) => {
    let usernameCreateTypes = [];
    if (this.customCfg?.getUsernameCreateTypes) {
      usernameCreateTypes = this.customCfg.getUsernameCreateTypes();
    }

    let cpfType = FormHelper.detectStringContent(userData?.CPF__c)

    if (
      (userData?.CPF__c) &&
      (
        !usernameCreateTypes ||
        (cpfType === 'cpf' && usernameCreateTypes.includes('cpf'))
      )
    ) {
      await this.setUsernameDoc(
        firebaseId,
        ToolHelper.getDigits(originalUserData?.CPF__c),
        ToolHelper.getDigits(userData?.CPF__c),
        FormHelper.detectStringContent(userData?.CPF__c)
      )
    }

    // if(
    //   userData.Email &&
    //   (!usernameCreateTypes || usernameCreateTypes.includes('email'))
    // ){
    //   await this.setUsernameDoc(firebaseId,originalUserData?.Email,userData.Email,'email')
    // }

    // if(
    //   userData.Username && 
    //   (!usernameCreateTypes || usernameCreateTypes.includes('username')) &&
    //   (userData.Username !== userData.CPF__c) &&
    //   (userData.Username !== userData.Email)
    // ){
    //   await this.setUsernameDoc(firebaseId,originalUserData?.Username,userData.Username,'text')
    // }
  }

  setUsernameDoc = async (firebaseId, originalUsername, username) => {
    const batch = writeBatch(this.db);

    batch.set(doc(this.db, 'Usernames', username), {
      UserFirebaseId: firebaseId
    })

    if (originalUsername?.length && originalUsername !== username) {
      batch.delete(doc(this.db, 'Usernames', originalUsername))
    }

    await batch.commit();
  }

  // Cria e registra um código de acesso para o usuário atual.
  sendAccessCode = async (UserFirebaseId) => {
    if(!UserFirebaseId) {
      return false;
    }

    var herokuDomain = await this.dataHelper.getHerokuDomain();
    var sendAccessCodeUrl = herokuDomain + '/generateAccessCode';
    var herokuData = {
      UserFirebaseId,
    }
    var result = {
      error: 'unknown-error',
    }

    try {
      var response = await fetch(sendAccessCodeUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(herokuData),
      });

      if (response.ok) {
        result = response.json();
      }
    }
    catch (e) {
      console.log('err', e);
    }

    return result;
  }

  sendPasswordDefinitionRequire = async (UserFirebaseId) => {
    if(!UserFirebaseId) {
      return false;
    }

    var herokuDomain = await this.dataHelper.getHerokuDomain();
    var sendAccessCodeUrl = herokuDomain + '/setPasswordDefinitionRequire';
    var herokuData = {
      UserFirebaseId,
    }
    var result = {
      error: 'unknown-error',
    }

    var response = await fetch(sendAccessCodeUrl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': HEROKU_AUTHORIZATION,
      },
      body: JSON.stringify(herokuData),
    });

    if (response.ok) {
      result = response.json();
    }

    return result;
  }

  // Verifica se o usuário atual precisa validar o código de acesso.
  isAccessCodeValid = async (user) => {
    if (!user) {
      return false;
    }

    return !user.AccessCodeRequireValidation;
  }

  // Verifica se o usuário precisa definir sua senha
  isRequiredPasswordDefinition = async (UserFirebaseId) => {
    const result = await this.findUserFirebaseDoc({ userFirebaseId: UserFirebaseId });
    return !result || result.PasswordDefinitionIsRequired;
  }

  getClientIP = async () => {
    if (global.ipAddress) {
      return global.ipAddress;
    }

    try {
      const ipFetch = await fetch('https://api.ipify.org?format=json');

      if (ipFetch.ok) {
        const ipRes = await ipFetch.json();
        global.ipAddress = ipRes.ip;
        return global.ipAddress;
      }
    }
    catch (e) {
      return null;
    }

    return null;
  }

  // Gera uma string de 6 dígitos onde os 2 últimos são números para conferência
  generateRandomAccessCode = (length = 6) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let charactersLength = characters.length;
    let charSum = 0;
    for (let i = 0; i < (length - 2); i++) {
      let chosenChar = characters.charAt(Math.floor(Math.random() * charactersLength));
      result += chosenChar;
      charSum += chosenChar.charCodeAt(0);
    }

    return result + (charSum + '').slice(-2);
  }

  // Valida o código do usuário no firebase
  validateAccessCode = async (UserFirebaseId, AccessCode) => {
    if(!UserFirebaseId || !AccessCode) {
      return false;
    }
    var herokuDomain = await this.dataHelper.getHerokuDomain();
    var validateAccessCodeUrl = herokuDomain + '/validateAccessCode';
    var herokuData = {
      UserFirebaseId,
      AccessCode,
    }

    var response = {
      error: 'unknown-error',
    }

    try {
      var result = await fetch(validateAccessCodeUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(herokuData),
      });

      if (result.ok) {
        response = result.json();
      }
    }
    catch (e) {
    }

    return response;
  }
  socioValidateAndSSO = async (plan, redirect_uri) => {
    let user = await this.getUser();

    // Lembrar que não precisa validar na receita federal se:
    // - Já tiver a flag que indica que validação já foi feita para este CPF

    /* não possui cpf ou não é válido na receita federal */
    if(!user?.CPF__c){
      return {
        success: false,
        code: 'invalid-cpf',
      }
    }
    /* -- 2024-05-31 -- Verificação do CPF será feito somente no cadastro FENG
    else{
      let result = await CruzeiroApi.CPFCheck(user, this.dataHelper);
      console.log('CPFCheck result',result)

      if(result?.error?.code === 'invalid-cpf'){
        return {
          success: false,
          code: 'invalid-cpf',
        }
      }
      // Se não é inválido e não foi bem sucedido, algo inesperado aconteceu.
      else if(!result?.success){
        return {
          success: false,
          code: 'unknown-error',
        }
      }
    }
    */

    /* não autenticou 2FA */
    // if(!await this.isValidAccessCode()){
    //   return {
    //     success: false,
    //     code: 'invalid-2fa',
    //   }
    // }

    if(!user?.VerifiedEmail__c){
      return {
        success: false,
        code: 'invalid-2fa',
      }
    } else if (user?.VerifiedEmail__c && (!user?.FengId__c && !user?.FengIdTemp)) {
      return {
        success: false,
        code: 'invalid-2fa',
      }
    }
    
    // fazer SSO
    return await CruzeiroApi.socioSSO({
      user,
      plan,
      dataHelper: this.dataHelper,
      redirect_uri
    });
  }
 getFengUserData = async (user, dataHelper) => {
    let data = {
      userId: user.FirebaseId__c,
    };

    let url = await dataHelper.getHerokuUrl('/CruzeiroGetIdPessoaByUserId');

    try {
      let response = await fetch(
        url + "?" + new URLSearchParams(data),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: HEROKU_AUTHORIZATION,
          },
        }
      );

      if (response.ok) {
        let result = await response.json();
        return result;
      }
    } catch (e) {
      console.log("e", e);
    }
  }

  registerUserOnFeng = async () => {
    let user = await this.getUser();

    if(!user?.FengId__c && !user.FengIdTemp){
      // Cadastrar na feng
      let registerResult = await CruzeiroApi.socioRegister(user, this.dataHelper)

      /* se o cadastro falhou */
      if(!registerResult?.fengId){
        return {
          success: false,
          code: 'feng-signup-fail',
        }
      }
      else{
        user.FengId__c = registerResult.fengId;    
        let userDocRef = await this.getUserRef();
        await setDoc(userDocRef, {
          FengIdTemp: registerResult.fengId
        }, { merge: true })
        await this.saveUser({ FengId__c: registerResult.fengId });
      }
    }
  }

  isValidAccessCode = async () => {
    let UserFirebaseId = await this.getUserFirebaseId();

    var checkAccessCodeUrl = await this.dataHelper.getHerokuUrl(HEROKU_CHECK_ACCESS_CODE,false);

    var herokuData = {
      UserFirebaseId,
    }

    var result = {
      error: 'unknown-error',
    }

    try {
      var response = await fetch(checkAccessCodeUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(herokuData),
      });

      
      if (response.ok) {
        result = await response.json();

        if(!result.error){
          return true;
        }
      }
    }
    catch (e) {
      console.log('err', e);
    }

    return false;
  }

  // Cria e registra um código de acesso para o usuário atual.
  sendAccessCode = async (sendMode) => {
    let UserFirebaseId = await this.getUserFirebaseId();

    var sendAccessCodeUrl = await this.dataHelper.getHerokuUrl(HEROKU_GENERATE_ACCESS_CODE,false);

    var herokuData = {
      UserFirebaseId,
      sendMode,
    }

    var result = {
      error: 'unknown-error',
    }

    try {
      var response = await fetch(sendAccessCodeUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(herokuData),
      });

      
      if (response.ok) {
        result = await response.json();
      }
    }
    catch (e) {
      console.log('err', e);
    }

    return result;
  }

  // Valida o código de acesso do usuário
  validateAccessCode = async (AccessCode) => {
    if(!AccessCode) {
      return false;
    }

    let UserFirebaseId = await this.getUserFirebaseId();

    var validateAccessCodeUrl = await this.dataHelper.getHerokuUrl(HEROKU_VALIDATE_ACCESS_CODE,false);
    var herokuData = {
      UserFirebaseId,
      AccessCode,
    }

    var response = {
      error: 'unknown-error',
    }

    try {
      var result = await fetch(validateAccessCodeUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(herokuData),
      });

      if (result.ok) {
        response = result.json();
      }
    }
    catch (e) {
    }

    return response;
  }

  // Busca em Email, CPF__c e Username por usuário em uso. Só retorna false caso todos estejam livres.
  usernamesExists = async (userData, userFirebaseId) => {
    return false; // TODO - fazer esse método
    if (userData.CPF__c) {
      let cleanCpf = ToolHelper.getDigits(userData.CPF__c)

      let result = await this.getHerokuUsernameDoc(cleanCpf);

      if (!result?.success && result?.error !== 'not-found') {
        return {
          success: false,
          error: {
            code: 'unknown-error',
          }
        }
      }
      else if (result?.username?.UserFirebaseId && result.username.UserFirebaseId !== userFirebaseId) {
        return {
          success: false,
          error: {
            code: 'auth/cpf-already-in-use',
          }
        }
      }
    }

    return false;
  }

  areTermsOk = async (userData) => {
    return userData.CheckedTerms;
  }

  createAuthUser = async (email,password) => {
    if (!email || !password) {
      return { success: false };
    }

    const auth = getAuth();

    // Se estiver logado
    if(auth.currentUser){
      // E não possuir um e-mail+senha associado a este user
      if(!this.getCurrentUserEmailProviderData()){
        try{
          // Neste fluxo, o user está logado com alguma rede social, e não possui login email+senha
          // Então cria um cadastro email+senha e linka as duas contas
          const emailCredential = EmailAuthProvider.credential(email,password);
          const userCred = await linkWithCredential(auth.currentUser, emailCredential)
          return {
            success: true,
            user: userCred.user,
          };
        }
        catch(e){
          console.log('e',e)
          return {
            success: false,
            error: e,
          }
        }
      }
      // Se possuir um e-mail+senha, falha
      else{
        return { success: false };
      }
    }
    else{
      try{
        await createUserWithEmailAndPassword(auth,email,password);
  
        return {
          success: true,
          user: auth.currentUser,
        }
      }
      catch (e) {
        console.log('e', e)
      }
    }

    return { success: false };
  }

  getCurrentUserEmailProviderData = () => {
    const auth = getAuth();

    if(!auth.currentUser?.providerData?.length){
      return false;
    }

    return auth.currentUser.providerData.find(o => o.providerId === 'password')
  }

  saveUser = async (userData) => {
    let originalUserData = await this.getUser();

    // Verifica se CPF está em uso
    if(userData?.CPF__c){
      if(await this.cpfExists(userData?.CPF__c)){
        return {
          success: false,
          error: {
            code: 'auth/cpf-already-in-use'
          }
        }
      }
    }

    // Verifica se algum username está em uso
    if (!originalUserData?.UID) {
      let usernameResult;

      if (usernameResult = await this.usernamesExists(userData, originalUserData?.FirebaseId__c)) {
        return usernameResult;
      }
    }

    // Verifica se todos os termos foram aceitos
    if (!originalUserData?.UID) {
      let termsAreOk = await this.areTermsOk(userData);

      if (!termsAreOk) {
        return {
          success: false,
          error: {
            code: 'missing-terms',
          }
        }
      }
    }

    userData.Password = userData.OriginalPassword = userData.Password || Math.random().toString();

    if (originalUserData?.UID) {
      userData.UID = originalUserData.UID;
    }

    if (!(userData.Email?.length) && originalUserData?.Email) {
      userData.Email = originalUserData.Email;
    }

    if (!userData.UID) {
      userData.AuthRegisterEmail = userData.Email;

      try {
        let createAuthUserResult = await this.createAuthUser(userData.AuthRegisterEmail,userData.Password);
        if (!createAuthUserResult?.success) {
          return {
            success: false,
            error: createAuthUserResult.error,
          }
        }

        return this.editUserData(userData, createAuthUserResult.user);
      }
      catch (error) {
        return {
          success: false,
          error,
        };
      }
    }
    else {
      return this.editUserData(userData, null, originalUserData)
    }
  }

  // Parâmetros de entrada:
  // - username OU userFirebaseId (em userData)
  // Parâmetros de saída:
  // - success: define se processo de verificação foi feito com sucesso
  // - code: [user-exists,user-needs-password-definition,user-does-not-exist]
  //
  // > Se user-needs-password-definition, o usuário será logado com uma senha fixa.
  //   Neste caso, é necessário registrar que usuário precisa autenticar seu acesso e definir sua senha.
  checkUserExists = async (userData, screen) => {
    let { username, userFirebaseId } = userData;

    if (!username && !userFirebaseId) {
      return {
        success: false,
      }
    }

    let userFirebaseDoc = await this.findUserFirebaseDoc(userData);

    if (userFirebaseDoc) {
      return {
        success: true,
        code: 'user-exists',
        UserFirebaseId: userFirebaseDoc?.UserFirebaseId,
      }
    }

    return {
      success: true,
      code: 'user-does-not-exist',
      UserFirebaseId: userFirebaseDoc?.UserFirebaseId,
    }
  }

  // Por enquanto a única forma de definir a senha, sem saber a senha antiga, é através de funções customizadas
  definePassword = async (UserFirebaseId, AccessCode, password) => {
    // custom definePassword
    if (this.customCfg?.definePassword) {
      let result = await this.customCfg.definePassword(UserFirebaseId, AccessCode, password, this);

      if (result.doLogin && result.username) {
        await this.userLogin(result.username, password);
      }

      if (result === false || (typeof result === 'object' && (!result.success || result.haltProcess))) {
        return result;
      }
    }

    return {
      success: true,
    }
  }

  setUserData = async (userData) => {
    let userDoc = { ...userData };

    if (userDoc.Email)
      userDoc.Email = userDoc.Email.toLowerCase();

    if (userDoc.AuthRegisterEmail)
      userDoc.AuthRegisterEmail = userDoc.AuthRegisterEmail.toLowerCase();

    delete userDoc.Password;
    delete userDoc.PasswordConfirmation;
    delete userDoc.OriginalPassword;
    delete userDoc.userFirebaseIdData;

    if (userDoc.Email || userDoc.AuthRegisterEmail) {
      userDoc.LoyaltyEmail__c = userDoc.Email = userDoc.Email || userDoc.AuthRegisterEmail;
    }

    if (userDoc.LoyaltyBirthdate__c)
      userDoc.LoyaltyBirthdate__c = moment(userDoc.LoyaltyBirthdate__c, 'DD/MM/YYYY').format('YYYY-MM-DD');

    if (userDoc?.CPF__c)
      userDoc.CPF__c = ToolHelper.getDigits(userDoc?.CPF__c);

    if (userData.FirebaseId__c)
      global.userFirebaseId = userData.FirebaseId__c;

    const ref = await this.getUserRef();

    const docResponse = setDoc(ref, {
      ...userDoc,
      lastSignInTime: serverTimestamp()
    }, { merge: true });

    return docResponse;
  }

  subscribeAllTopics = async () => {
    /*
        TODO - integrar isso a buscar o token
        var notificationsTopics = await this.getTopics();
        var topics = '';
    
        for(i = 0; i < notificationsTopics.length; i++) {
          docData = notificationsTopics[i];
          topics += docData.FirebaseId__c + ';';
          getMessaging().subscribeToTopic(token, docData.FirebaseId__c);
        }
    
        return topics
    */
  }

  getTopics = async () => {
    let topicsQ = query(collection(this.db, 'NotificationTopic'), orderBy('Order__c'));
    let topicsDocs = await getDocs(topicsQ);

    let topics = [];
    if (topicsDocs.size > 0) {
      topicsDocs.forEach((doc) => {
        let docData = doc.data();

        if (docData.FirebaseId__c !== null && docData.FirebaseId__c !== '')
          topics.push(docData)
      })
    }

    return topics;
  }

  snapUserPoints = (callback) => {
    let pointsQ = query(collection(this.db, 'Users', global.userFirebaseId, 'PointStatement'), orderBy('PointDate__c', 'desc'));

    let unsub = onSnapshot(pointsQ, (snapshot) => {
      let points = this.dataHelper.getList(snapshot);
      callback(points);
    })

    return unsub
  }

  getSocialProvider = (providerId) => {
    let provider;

    switch(providerId){
      case 'google.com':
        provider = new GoogleAuthProvider();
        provider.addScope('email');
      break;
      case 'facebook.com':
        provider = new FacebookAuthProvider();
        provider.addScope('email');
        provider.setCustomParameters({
          display: 'popup'
        })
      break;
      case 'apple.com':
        provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
      break;
      default:
        return false;
    }

    return provider;
  }

  getUserPoints = async (qty = 10, startAfterDoc = null) => {
    let userDocRef = await this.getUserRef();
    let pointsQ = query(collection(userDocRef, 'PointStatement'), orderBy('PointDate__c', 'desc'), limit(qty));
    if (startAfterDoc) {
      pointsQ = query(pointsQ, startAfter(startAfterDoc));
    }
    let pointsDoc = await getDocs(pointsQ);

    let data = [];
    let lastDoc = null;

    if (pointsDoc.docs.length) {
      data = pointsDoc.docs.map((doc) => {
        lastDoc = doc;
        return doc.data();
      })
    }

    return { data, lastDoc };
  }

  googleLogin = async () => {
    const provider = this.getSocialProvider('google.com');
    const auth = getAuth();

    try{
      let result = await signInWithPopup(auth, provider)

      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      if(result?.user){
        const user = result.user;

        // Verifica se user já tem registro em Users (se não tiver, assume-se novo usuário)
        let email = user?.providerData[0]?.email;

        let ufbresult = await this.findUserFirebaseDoc({ username: email });
        const isNewUser = !(ufbresult?.UserFirebaseId?.length)

        // Se não é novo usuário, mas não está linkado à conta de e-mail/senha,
        // é necessário fazer a ligação
        const needsLinking = !isNewUser && !this.getCurrentUserEmailProviderData();

        return {
          success: true,
          isNewUser,
          needsLinking,
          signInResult: result,
          resultUser: user,
        }
      }
      else{
      }
    }
    catch(error){
      return {
        success: false,
        error: {
          message: 'Não foi possível recuperar o usuário'
        },
      }
    }
  }

  // TODO - Precisa refatorar. Não é mais para receber callback
  facebookLogin = (callback) => {
    const provider = this.getSocialProvider('facebook.com');

    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;

        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;

        // Verifica se user já tem registro em Users (se não tiver, assume-se novo usuário)
        let email = user?.providerData[0]?.email;

        const isNewUser = (!email || !(await this.firestoreEmailExists(email)));

        // Se não é novo usuário, mas não está linkado à conta de e-mail/senha,
        // é necessário fazer a ligação
        const needsLinking = !isNewUser && !this.getCurrentUserEmailProviderData();

        callback({
          success: true,
          isNewUser,
          needsLinking,
          signInResult: result,
        })
      })
      .catch((error) => {
        const credential = FacebookAuthProvider.credentialFromError(error);

        callback({
          success: false,
          error,
        })
      });
  }

  appleLogin = async () => {
    const provider = this.getSocialProvider('apple.com');

    const auth = getAuth();

    try{
      const result = await signInWithPopup(auth, provider);
  
      const user = result.user;
  
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
  
      // Verifica se user já tem registro em Users (se não tiver, assume-se novo usuário)
      let email = user?.providerData[0]?.email;
  
      const isNewUser = (!email || !(await this.firestoreEmailExists(email)));
  
      // Se não é novo usuário, mas não está linkado à conta de e-mail/senha,
      // é necessário fazer a ligação
      const needsLinking = !isNewUser && !this.getCurrentUserEmailProviderData();
  
      return {
        success: true,
        isNewUser,
        needsLinking,
        signInResult: result,
      }
    }
    catch(error){
      const credential = OAuthProvider.credentialFromError(error);

      return {
        success: false,
        error,
      }
    }
  }

  userLinkLogin = async (username, password, resultUser) => {

    if(!username || !password || !resultUser){
      return {
        success: false,
        error: { code: 'auth/user-not-found' },
      }
    }

    const auth = getAuth();
    
    const prevUser = auth.currentUser;

    if(
      prevUser.providerData.length !== 1 ||
      prevUser.providerData[0].providerId === 'password'
    ){
      return {
        success: false,
        error: { code: 'auth/invalid-user-to-link' },
      }
    }

    const prevUserProviderId = prevUser.providerData[0].providerId;

    try{
      // Verifica se senha está correta (irá para o catch caso não estiver)
      await signInWithEmailAndPassword(auth, username, password)

      // Deleta o user social (necessário para criá-lo novamente fazendo o link)
      await deleteUser(prevUser);

      // Depois que roda deleteUser, o usuário é deslogado. Precisa logá-lo novamente.
      // O deleteUser não pode ser a primeira ação pois se a senha estiver errada ele perde o prevUser
      await signInWithEmailAndPassword(auth, username, password)

      const provider = this.getSocialProvider(prevUserProviderId);
  
      await linkWithPopup(auth.currentUser, provider)
    }
    catch(e){
      console.log('e',e)

      return {
        success: false,
        error: e,
      }
    }

    return {
      success: true,
    }
  }

  recoverPassword = async (username) => {
    let userFirebaseDoc = await this.findUserFirebaseDoc({ username });

    if (!userFirebaseDoc) {
      return {
        success: false,
        error: { code: 'auth/user-not-found' },
      }
    }

    let email = userFirebaseDoc.Email;

    const auth = getAuth();

    // custom beforeRecoverPassword
    if (this.customCfg?.beforeRecoverPassword) {
      let result = await this.customCfg.beforeRecoverPassword(username, userFirebaseDoc, 'recoverpassword', this)
      if (result === false || (typeof result === 'object' && (!result.success || result.haltProcess))) {
        if (result?.code === 'user-needs-password-definition' || result?.code === 'user-exists') {
          await this.sendAccessCode(userFirebaseDoc.UserFirebaseId);
        }

        if (typeof result === 'object' && userFirebaseDoc?.UserFirebaseId) {
          result.UserFirebaseId = userFirebaseDoc.UserFirebaseId;
        }

        return result;
      }
    }

    try {
      await sendPasswordResetEmail(auth, email)
    }
    catch (error) {
      return {
        success: false,
        error
      }
    }

    return {
      success: true,
      UserFirebaseId: userFirebaseDoc.UserFirebaseId,
    }
  }

  // userData: currentPassword / newPassword
  checkUserPassword = async (userData) => {
    let user = this.getAuthUser();

    // custom beforeChangePassword
    if (this.customCfg?.beforeChangePassword) {
      let customData = { ...userData, Email: user.email }
      let result = await this.customCfg.beforeChangePassword(customData)

      if (result.haltProcess || !result.success) {
        return result;
      }
    }

    const credential = EmailAuthProvider.credential(user.email, userData.CurrentPassword);

    try {
      await reauthenticateWithCredential(user, credential);

      return {
        success: true
      };
    }
    catch (error) {
      return {
        success: false,
        error
      }
    }
  }

  // userData: currentPassword / newPassword
  changePassword = async (userData) => {
    let user = this.getAuthUser();

    // custom beforeChangePassword
    if (this.customCfg?.beforeChangePassword) {
      let customData = { ...userData, Email: user.email }
      let result = await this.customCfg.beforeChangePassword(customData)

      if (result.haltProcess || !result.success) {
        return result;
      }
    }

    const credential = EmailAuthProvider.credential(user.email, userData.CurrentPassword);

    try {
      let reauthResult = await reauthenticateWithCredential(user, credential);

      await updatePassword(user, userData.NewPassword);

      // Garante que não há mais flag de obrigar troca de senha
      const userFirebaseIdRef = await this.getUserFirebaseIdRef();

      await setDoc(userFirebaseIdRef, {
        PasswordDefinitionIsRequired: false,
      }, { merge: true });

      return {
        success: true
      };
    }
    catch (error) {
      return {
        success: false,
        error
      }
    }
  }

  logout = (successCallback, errorCallback) => {
    const auth = getAuth();

    if (auth?.currentUser) {
      signOut(auth).then(() => {
        global.userFirebaseId = null;
        global.userType = null;
        global.userTypes = [];

        successCallback();
      }).catch((error) => {
        errorCallback(error);
      });
    }
    else {
      successCallback();
    }
  }

  logoutAsync = async () => {
    const auth = getAuth();

    try {
      await signOut(auth);
      global.userFirebaseId = null;
      global.userType = null;
      global.userTypes = [];

      return {
        success: true
      }
    }
    catch (error) {
      return {
        success: false,
        error
      }
    }
  }

  updateLoyalty = async () => {
    let userDocRef = await this.getUserRef();
    let userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      let userData = userDoc.data();
      return await this.createLoyalty(userData);
    }

    return false;
  }

  createLoyalty = async (userData) => {
    let userDocRef = await this.getUserRef();
    setDoc(userDocRef, { lastCreateLoyaltyTime: serverTimestamp() }, { merge: true })

    if (!userData.LoyaltyEmail__c || !userData.LastName) {
      return;
    }

    var sfUserData = {
      firstName: userData.FirstName,
      lastName: userData.LastName,
      phone: userData.LoyaltyPhone__c,
      birthdate: userData.LoyaltyBirthdate__c,
      street: userData.LoyaltyStreet__c,
      district: userData.acg_AddressDistrict__c,
      city: userData.LoyaltyCity__c,
      state: userData.LoyaltyState__c,
      country: userData.LoyaltyCountry__c,
      postalCode: userData.LoyaltyPostalCode__c,
      foreigner: userData.Foreigner__c == 1,
      addressNumber: userData.acg_AddressNumber__c,
      addressComplement: userData.acg_AddressComplement__c,
      email: userData.LoyaltyEmail__c ? userData.LoyaltyEmail__c.toLowerCase() : '',
      facebookId: userData.FacebookId__c,
      googleId: userData.GoogleId__c,
      twitterId: userData.TwitterId__c,
      firebaseId: userData.FirebaseId__c,
      messagingToken: userData.MessagingToken__c,
      topics: userData.Topics__c,
      cpf: userData.CPF__c,
      profissao: userData.Profissao__c,
      areaAtuacao: userData.AreaAtuacao__c,
      lojaRelacionamento: userData.LojaRelacionamento__c,
      userCategory: userData.LoyaltyCategory__c,
      photoURL: userData.photoURL,
      culturas: userData.acg_Culturas__c,
      verifiedEmail: userData.VerifiedEmail__c,
      fengId: userData.FengId__c
    }

    var loyaltyUrl = await this.dataHelper.getHerokuUrl(HEROKU_LOYALTY_URL);

    let success = false;
    let err;

    try {
      var response = await fetch(loyaltyUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(sfUserData),
      });

      if (response?.ok) {
        let responseText = await response.text();

        if (responseText === 'Message received' || responseText === 'Logged error') {
        }
        else {
          err = responseText;
        }

        if (responseText === 'Message received') {
          success = true;
        }
      }
      else {
        err = response?.statusText;
      }

    } catch (error) {
      err = error;
      console.warn('createLoyaltyException', error);
    }

    return success;
  }

  setLastTimeOnline = async () => {
    const userData = await this.getUser();

    if (!userData)
      return null;

    const dateRef = moment().format('YYYY-MM-DD');
    const lastOnline = userData.lastTimeOnline ? moment(userData.lastTimeOnline.toDate()).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');

    const lastTimeNull = userData.lastTimeOnline == null;
    const isNew = dateRef > lastOnline;

    if (global.lastDailyLogin !== dateRef && (lastTimeNull || isNew)) {
      global.lastDailyLogin = dateRef;
      await GameficationHelper.addRow(this, POINT_ACTIONS.DAILY_LOGIN);
    }

    let userDocRef = await this.getUserRef();

    if (userDocRef)
      await setDoc(userDocRef, { lastTimeOnline: serverTimestamp() }, { merge: true });
  }

  setProgramOptOut = async () => {
    const userData = await this.getUser();

    if (!userData)
      return null;

    const sfUserData = {
      cpf: userData.CPF__c,
      email: userData.LoyaltyEmail__c.toLowerCase(),
      lastName: userData.LastName,
      programOptOut: true,
    }

    try {
      var loyaltyUrl = await this.dataHelper.getHerokuUrl(HEROKU_LOYALTY_URL);

      var response = await fetch(loyaltyUrl, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(sfUserData),
      });

      let userDocRef = await this.getUserRef();
      await setDoc(userDocRef, { acg_ProgramOptOut__c: true }, { merge: true })

      return {
        success: true
      };
    } catch (error) {
      console.log('erro no envio', error)
    }
  }

  uploadAvatar = async (img) => {
    const userFirebaseId = await this.getUserFirebaseId();
    const storage = getStorage();
    const storageRef = ref(storage, `ProfilePictures/${userFirebaseId}.jpeg`);
    const metadata = { contentType: 'image/jpeg' };

    uploadBytes(storageRef, img, metadata).then((snapshot) => {
      getDownloadURL(storageRef).then(async (url) => {
        const ref = await this.getUserRef();

        const docResponse = setDoc(ref, {
          photoURL: url,
        }, { merge: true });

        await this.updateLoyalty();

        return url;
      })
    });
  }

  setDocumentViewed = async (documentId) => {
    let userId = await this.getUserFirebaseId();

    if (documentId && userId) {
      let viewRef = doc(this.db, 'Users', userId, 'View', documentId);
      let viewDoc = await getDoc(viewRef);

      if (!viewDoc.exists()) {
        await GameficationHelper.addRow(this, POINT_ACTIONS.EVENT_CLICK_NEWS, documentId);
      }

      let viewData = {
        userId,
        lastViewDate: serverTimestamp(),
        documentId
      }

      setDoc(viewRef, viewData, { merge: true });
    }
  }

  setBannerViewed = async (bannerId) => {
    await GameficationHelper.addRow(this, POINT_ACTIONS.EVENT_CLICK_ADS, bannerId);

    return true;
  }

  getFinishedSurveyIds = async (isQuiz) => {
    const finishedQ = collection(this.db, 'Users', await this.getUserFirebaseId(), isQuiz ? 'QuizAnswers' : 'SurveyAnswers');
    const finishedDoc = await getDocs(finishedQ);
    const finishedSurveys = this.dataHelper.docsToArray(finishedDoc);

    let out = finishedSurveys.map((finishedSurvey) => finishedSurvey.Id);
    return out;
  }

  getSurveyQuestionAnswers = async (isQuiz) => {
    const answersQ = collection(this.db, 'Users', await this.getUserFirebaseId(), isQuiz ? 'QuizAnswersOption' : 'SurveyAnswersOption');
    const answersDoc = await getDocs(answersQ);
    const answers = this.dataHelper.docsToArray(answersDoc);

    return answers;
  }

  surveySendAnswer = async (
    survey,
    question,
    selectedOptionIds, // array de strings
    textAnswer,
  ) => {

    let cgny2__Action__c = POINT_ACTIONS.ACTION_VOTE_SURVEY_ANSWER + question.RecordType.DeveloperName.toUpperCase();
    let rightAnswer = false;
    const isQuiz = survey.FirebasePath__c === 'Quiz';

    if (typeof selectedOptionIds === 'object' && selectedOptionIds.length > 0) {
      //for (let i = 0; i < selectedOptionIds.length; i++) {
      const optId = selectedOptionIds[0];


      if (isQuiz) {
        if (optId === question.RightAnswer__c) {
          rightAnswer = true;
          cgny2__Action__c = POINT_ACTIONS.ACTION_QUIZ_RIGHT_ANSWER;
        }
        else {
          cgny2__Action__c = POINT_ACTIONS.ACTION_QUIZ_WRONG_ANSWER;
        }
      }

      await GameficationHelper.addRow(
        this,
        cgny2__Action__c,
        question.Id,        //cgny2__GenericId__c,
        undefined,          //cgny2__Event__c,
        textAnswer,         //cgny2__Value__c,
        undefined,          //cgny2__AdvertisingItem__c,
        undefined,          //cgny2__Gallery__c,
        undefined,          //cgny2__News__c,
        question.Survey__c, //cgny2__Survey__c,
        optId,              //cgny2__SurveyQuestionOption__c,
        question.Id,        //cgny2__SurveyQuestion__c,
        undefined,          //cgny2__Voucher__c
        undefined,          //appUser
        undefined,          //recordType
        undefined,          //cgny2__NumberValue__c
        undefined,          //cgny2__StartUpMessage__c
      );
      // }
    }
    else {
      // Uma resposta pode ter apenas Answer e não ter opções selecionadas
      await GameficationHelper.addRow(
        this,
        cgny2__Action__c,
        question.Id,        //cgny2__GenericId__c,
        undefined,          //cgny2__Event__c,
        textAnswer,         //cgny2__Value__c,
        undefined,          //cgny2__AdvertisingItem__c,
        undefined,          //cgny2__Gallery__c,
        undefined,          //cgny2__News__c,
        question.Survey__c, //cgny2__Survey__c,
        undefined,          //cgny2__SurveyQuestionOption__c,
        question.Id,        //cgny2__SurveyQuestion__c,
        undefined,          //cgny2__Voucher__c
        undefined,          //appUser
        undefined,          //recordType
        undefined,          //cgny2__NumberValue__c
        undefined,          //cgny2__StartUpMessage__c
      );
    }

    let tmpQuestionAnswer = {
      Id: question.Id,
      question,
      rightAnswer,
      actionDate: serverTimestamp(),
    };

    if (survey.Status__c !== 'Test') {
      let questionDoc = doc(this.db, 'Users', await this.getUserFirebaseId(), isQuiz ? 'QuizAnswersOption' : 'SurveyAnswersOption', question.Id);
      setDoc(questionDoc, tmpQuestionAnswer);
      //   this.props.surveyQuestionAnswersRef.doc(question.Id).set(tmpQuestionAnswer);

    }
  }

  surveySendClosure = async (survey) => {
    const isQuiz = survey.FirebasePath__c === 'Quiz';
    let surveyDoc = doc(this.db, 'Users', await this.getUserFirebaseId(), isQuiz ? 'QuizAnswers' : 'SurveyAnswers', survey.Id);

    setDoc(surveyDoc, {
      Id: survey.Id,
      actionDate: serverTimestamp()
    })

    const cgny2__Action__c = isQuiz ? POINT_ACTIONS.ACTION_QUIZ_COMPLETE : POINT_ACTIONS.ACTION_VOTE_SURVEY_COMPLETE;
    const cgny2__GenericId__c = survey.Id;

    GameficationHelper.addRow(
      this,
      cgny2__Action__c,
      cgny2__GenericId__c,
      undefined, //cgny2__Event__c,
      undefined, //cgny2__Value__c,
      undefined, //cgny2__AdvertisingItem__c,
      undefined, //cgny2__Gallery__c,
      undefined, //cgny2__News__c,
      survey.Id, //cgny2__Survey__c,
      undefined, //cgny2__SurveyQuestionOption__c,
      undefined, //cgny2__SurveyQuestion__c,
      undefined, //cgny2__Voucher__c
      undefined, //appUser
      undefined, //recordType
      undefined, //cgny2__NumberValue__c
      undefined  //cgny2__StartUpMessage__c
    );
  }

  snapCases = async (callback) => {
    let userFirebaseId = await this.getUserFirebaseId();

    if (userFirebaseId) {
      let casesQ = query(collection(this.db, 'Users', userFirebaseId, 'Case'), orderBy('CreatedDate', 'desc'));

      let unsub = onSnapshot(casesQ, (snapshot) => {
        let cases = this.dataHelper.getList(snapshot);
        callback(cases);
      })

      return { cases: unsub }
    }

    return null;
  }

  getCaseReasonsToAskUserDocument = () => {
    return ['Correção de e-mail e/ou telefone', 'Alteração Cadastral'];
  }

  saveNewCase = async (caseData, inputs) => {
    let aInputs = Object.entries(inputs);
    let requiredFields = [];
    aInputs.every(aInput => {
      if (aInput[1].isRequired)
        requiredFields.push(aInput[0]);
      return true;
    })

    if (!FormHelper.validateRequiredFields(inputs, caseData)) {
      return {
        success: false,
      }
    }

    const userData = await this.getUser();

    const requestBody = {
      Type: caseData.Type,
      SubType__c: caseData.Reason,
      Description: caseData.Message,
      RecordTypeId: caseData.RecordTypeId,
      Name: caseData.Name,
      SuppliedName: caseData.Name,
      SuppliedEmail: caseData.Email,
      Subject: caseData.Subject,
      ContactCPF__c: caseData.CPF__c,
      Origin: "Portal",
    };

    let images = JSON.parse(caseData.Images__c);

    for (let index = 0; index < images.length; index++) {
      const element = images[index];
      if (element && element.base64Image) {
        requestBody[`cgny2__Attachment${index + 1}__c`] = JSON.stringify({
          name: element.fileName,
          content: element.base64Image,
        });
      }
    }

    if (userData?.Id) {
      requestBody.ContactId = userData.Id;
      requestBody.Name = userData.FirstName + ' ' + userData.LastName;
      requestBody.SuppliedEmail = userData.LoyaltyEmail__c;
      requestBody.Phone = userData.LoyaltyPhone__c;
    }

    let caseUrl = await this.dataHelper.getHerokuUrl(HEROKU_CASE_WITH_FILE_URL);

    try {
      let response = await fetch(caseUrl, {
        method: 'POST',

        headers: {
          'Content-Type': 'application/json',
          'Authorization': HEROKU_AUTHORIZATION,
        },
        body: JSON.stringify(requestBody),
      });

      return {
        success: true,
      }
    }
    catch (e) {
      return {
        success: false,
        error: e,
      }
    }
  }

  snapRaffleWinners = async (callback, raffleId) => {
    let raffleWinnersQ = query(collection(this.db, 'DrawnReward'));

    return onSnapshot(raffleWinnersQ,
      (snapshot) => {
        let raffleWinners = this.dataHelper.getList(snapshot);

        raffleWinners = raffleWinners.filter((raffleWinner) =>
          raffleWinner?.Raffle__c && raffleWinner.Raffle__c === raffleId
        )

        callback(raffleWinners);
      }
    )
  }

  snapRafflesLuckyNumbers = async (callback, raffleId, userId) => {
    let luckyNumberAmountQ = query(
      collection(
        this.db,
        'LuckyNumbers'
      ),
      where('Contact__c', '==', userId),
      where('Raffle__c', '==', raffleId),
    );

    return onSnapshot(luckyNumberAmountQ,
      (snapshot) => {
        let luckyNumberAmount = this.dataHelper.getList(snapshot);

        callback(luckyNumberAmount);
      }
    )
  }

  getRafflesLuckyNumbers = async (raffleId, userId) => {
    if (!userId) return [];

    let luckyNumberAmountQ = query(
      collection(
        this.db,
        'LuckyNumbers',
      ),
      where('Contact__c', '==', userId),
      where('Raffle__c', '==', raffleId),
    );
    let luckyNumberAmountDocs = await getDocs(luckyNumberAmountQ)

    const luckyNumbers = [];

    if (luckyNumberAmountDocs.size > 0) {
      luckyNumberAmountDocs.forEach((doc) => {
        let data = doc.data();
        luckyNumbers.push(data)
      })
    }

    return luckyNumbers;
  }

  getRafflesDetails = async (raffleId, userId) => {
    if (!userId) return [];

    let RafflesDetails = query(
      collection(
        this.db,
        'Raffles',
      ),

      // where('Contact__c', '==', userId),
      where('Id', '==', raffleId),

    );
    let RafflesDetailsDocs = await getDocs(RafflesDetails)

    const raffles_data = [];

    if (RafflesDetailsDocs.size > 0) {
      RafflesDetailsDocs.forEach((doc) => {
        let data = doc.data();
        raffles_data.push(data)
      })
    }

    return raffles_data.length > 0 ? raffles_data[0] : {};
  }

  snapRafflesSalesAmount = async (callback, raffleId, userId) => {
    if (!userId) return [];
    let rafflesSalesAmountQ = query(
      collection(
        this.db,
        'RafflePerformances'
      ),
      where('acg_Contact__c', '==', userId),
      where('Raffle__c', '==', raffleId),
    );

    return onSnapshot(rafflesSalesAmountQ,
      (snapshot) => {
        let rafflesSalesAmount = this.dataHelper.getList(snapshot);

        callback(rafflesSalesAmount);
      }
    )
  }

  snapRaffles = (callback, userId, loyaltyCategory = '') => {
    if (!userId) return [];

    let type = 'NULO';

    if (loyaltyCategory === 'Time de vendas do canal') {
      type = 'Consultor';
    } else if (loyaltyCategory === 'Agricultor') {
      type = 'Produtor';
    }

    // let rafflesQ = query(collection(this.db, 'Raffles'), where('acg_Type__c', '==', type));
    let rafflesQ = query(collection(this.db, 'Raffles'));

    return onSnapshot(rafflesQ, (snapshot) => {
      const toDay = moment();
      let raffles = this.dataHelper.getList(snapshot);

      raffles = raffles.filter((raffle) => {
        const startDate = moment(raffle.acg_StartDate__c, 'YYYY/MM/DD').startOf('day');
        const endDate = moment(raffle.acg_EndDate__c, 'YYYY/MM/DD').endOf('days');

        if (!raffle.acg_StartDate__c || !raffle.acg_EndDate__c) {
          return false;
        }

        return startDate.isSameOrBefore(toDay) && endDate.isSameOrAfter(toDay)
      });

      callback(raffles);
    })
  }

  snapGroupParticipants = (callback, userId, id) => {
    if (!userId) return [];
    let rafflesQ = query(
      collection(this.db, 'RaffleParticipants'),
      where('acg_Contact__c', '==', userId),
      where('acg_Raffle__c', '==', id)
    );
    return onSnapshot(rafflesQ,
      (snapshot) => {
        let raffles = this.dataHelper.getList(snapshot);
        callback(raffles);
      });

  };

  snapRaffleRankingPerformanceByUser = (callback, userId, rankingId) => {
    if (!userId) return [];
    let rafflesQ = query(
      collection(this.db, 'RaffleRanking'),
      where('acg_Contact__c', '==', userId),
      // where('acg_RaffleVersion__c', '==',  "a585E000000UbgpQAC")
    );
    return onSnapshot(rafflesQ,
      (snapshot) => {
        // console.log('snapshot', snapshot)
        let raffles = this.dataHelper.getList(snapshot);
        callback(raffles);
      });

  };

  snapRaffleRankingsData = (callback, raffleId) => {

    let rafflesQ = query(
      collection(this.db, 'RaffleRanking'),

    );
    return onSnapshot(rafflesQ,
      (snapshot) => {
        // console.log('snapshot', snapshot)
        let raffles = this.dataHelper.getList(snapshot);
        callback(raffles);
      });

  };

  snapRafflesParticipants = (callback, userId) => {
    if (!userId) return [];

    let rafflesQ = query(
      collection(this.db, 'RaffleParticipants'),
      where('acg_Contact__c', '==', userId)
    );

    return onSnapshot(rafflesQ,
      (snapshot) => {
        const toDay = moment();
        let raffles = this.dataHelper.getList(snapshot);

        // raffles = raffles.filter((raffle) => {
        //   const startDate = moment(raffle.acg_Raffle__r?.acg_StartDate__c, 'YYYY/MM/DD').startOf('day');
        //   const endDate = moment(raffle.acg_Raffle__r?.acg_EndDate__c, 'YYYY/MM/DD').endOf('days');

        //   if (!raffle.acg_Raffle__r?.acg_StartDate__c || !raffle.acg_Raffle__r?.acg_EndDate__c) {
        //     return false;
        //   }

        //   return startDate.isSameOrBefore(toDay) && endDate.isSameOrAfter(toDay)
        // })

        callback(raffles);
      }
    )
  }

  snapExpiredPoints = async (callback) => {
    if (!global.userFirebaseId) {
      callback(null)
      return false
    }
    let expiredPointsQ = query(collection(this.db, 'Users', global.userFirebaseId, 'PointStatement'));
    expiredPointsQ = query(expiredPointsQ, where('Expired__c', '==', false))

    return onSnapshot(expiredPointsQ, (snapshot) => {
      let expiredPoints = this.dataHelper.getList(snapshot);

      expiredPoints = expiredPoints.map(point => {
        if (!point?.ExpirationDate__c) return point;
        const dateRef = new Date(point?.ExpirationDate__c)
        dateRef.setUTCHours(dateRef.getUTCHours() - (dateRef.getTimezoneOffset() / 60));

        const localISOString = dateRef.toISOString();
        //toLocaleString('pt-BR', { timeZone: 'UTC' });

        return {
          ...point,
          ExpirationDate__c: localISOString,
        }
      });




      callback(expiredPoints);
    }), (error) => {
      console.log('error', error)
    }

  }


  // Novo "snapUserPoints"
  snapPoints = (callback, listType = 'transactions', filters = {}) => {
    if (!global.userFirebaseId) {
      callback(null)
      return false
    }
    let pointsQ = query(collection(this.db, 'Users', global.userFirebaseId, 'PointStatement'));
    let hasSetDateOrder = false;
    let field;

    if (filters.period) {
      if (filters.period.includes('expiration')) {
        field = 'ExpirationDate__c';
      }
      else {
        field = 'PointDate__c';
      }

      let startDate;
      let endDate;

      if (filters.period.includes('-last')) {
        startDate = new Date();
        let daysQty = parseInt(filters.period.split('-')[2])
        startDate.setDate(startDate.getDate() - daysQty);
      }
      else if (filters.period.includes('-period')) {
        if (filters.startDate) {
          startDate = new Date(filters.startDate.split('/').reverse().join('-'))
        }

        if (filters.endDate) {
          endDate = new Date(filters.endDate.split('/').reverse().join('-'))
        }
      }

      if (startDate) {
        let startOfTheDay = startDate.toISOString().split('T')[0] + 'T00:00:00';
        pointsQ = query(pointsQ, where(field, '>=', startOfTheDay))
        pointsQ = query(pointsQ, orderBy(field, 'desc'))
        hasSetDateOrder = true;
      }

      if (endDate) {
        let endOfTheDay = endDate.toISOString().split('T')[0] + 'T23:59:59';
        pointsQ = query(pointsQ, where(field, '<=', endOfTheDay))

        if (!startDate) {
          pointsQ = query(pointsQ, orderBy(field, 'desc'))
          hasSetDateOrder = true;
        }
      }
    }

    if (filters.uf) {
      pointsQ = query(pointsQ, where('acg_GamifiedSalesRecord__r.acg_SalesRecord__r.acg_Invoice__r.acg_UF__c', '==', filters.uf))
    }

    if (filters.action) {
      pointsQ = query(pointsQ, where('Action__c', '==', filters.action))
    }

    if (!hasSetDateOrder || field !== 'PointDate__c')
      pointsQ = query(pointsQ, orderBy('PointDate__c', 'desc'))

    return onSnapshot(pointsQ,
      (snapshot) => {
        let points = this.dataHelper.getList(snapshot);


        // removendo linhas que possuem acg_StatusConsultant__c
        points = points.filter(point => !point?.acg_StatusConsultant__c?.length).sort((a, b) => {
          const menorValue = a.PointDate__c || a.PointDate__c;
          const maiorValue = b.PointDate__c || b.PointDate__c;
          if (menorValue > maiorValue) {
            return -1;
          } else if (menorValue < maiorValue) {
            return 1;
          } else {
            return 0;
          }
        });


        points = points.filter(point =>
          (!point?.Fielo_PointStatementId__c?.length) === (listType === 'transactions'))

        if (filters.search) {
          let searchLower = filters.search.toLowerCase();
          points = points.filter((point) => {
            return point.Description__c?.toLowerCase().includes(searchLower)
          })
        }



        points = points.map(point => {
          if (!point?.ExpirationDate__c) return point;
          const dateRef = new Date(point?.ExpirationDate__c)
          dateRef.setUTCHours(dateRef.getUTCHours() - (dateRef.getTimezoneOffset() / 60));
          const localISOString = dateRef.toISOString();
          return {
            ...point,
            ExpirationDate__c: localISOString,
          }
        });


        callback(points);
      }, (error) => {
        console.log('error', error)
      })
  }

  isConsultant = (userData) => {
    return userData?.acg_ProfileCodes__c?.includes('consultor');
  }

  snapFavorite = (callback) => {
    let favoriteQ = query(collection(this.db, 'Users', global.userFirebaseId, 'FavoriteAccount'));

    let unsub = onSnapshot(favoriteQ, (snapshot) => {
      let favorites = this.dataHelper.getList(snapshot);
      callback(favorites);
    })

    return unsub;
  }

  updateFavorite = async (taxId) => {
    const formatoISO8601 = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');

    const userRef = doc(this.db, 'Users', global.userFirebaseId);
    const subcollectionRef = collection(userRef, 'FavoriteAccount');

    const subCollectionSnapshot = await getDocs(subcollectionRef);

    subCollectionSnapshot.forEach(async (subDocSnapshot) => {
      if (subDocSnapshot.exists()) {
        const dataToUpdate = subDocSnapshot.data();

        if (dataToUpdate.acg_FavoriteAccount__r.acg_TaxId__c === taxId) {

          dataToUpdate.acg_InactivationDate__c = formatoISO8601;

          try {
            await updateDoc(subDocSnapshot.ref, dataToUpdate);
            return true;
          } catch (error) {
            return false;
          }
        }
      } else {
      }
    });
  };

  getLoyaltyItems = async function(limitQty) {
    let user = await this.getUser();

    if(!user){
      return [];
    }

    let loyaltyItems = [];

    if(user.LoyaltyCategory__c){
      let loyaltyQ = query(collection(this.db,'LoyaltyType'),where('Name','==',user.LoyaltyCategory__c));

      if(limitQty){
        loyaltyQ = query(loyaltyQ,limit(limitQty));
      }

      let loyaltyDocs = await getDocs(loyaltyQ)
      const loyalties = this.dataHelper.docsToArray(loyaltyDocs);

      if(loyalties.length){
        let docData = loyalties[0];

        if(docData.LoyaltyTypeItems__r?.records?.length){
          loyaltyItems = docData.LoyaltyTypeItems__r.records;
          loyaltyItems.sort((a,b) => {
            if(!a.Order__c) return 1;
            if(!b.Order__c) return -1;

            return (a.Order__c < b.Order__c) ? -1 : 1
          })
        }
      }
    }

    return loyaltyItems;
  }

  getPayWallLoyaltyItems = async function (limitQty, name, benefitsToSHow) {
    let loyaltyItems = [];

    let loyaltyQ = query(
      collection(this.db, "LoyaltyType"),
      where("Name", "==", name)
    );

    if (limitQty) {
      loyaltyQ = query(loyaltyQ, limit(limitQty));
    }

    let loyaltyDocs = await getDocs(loyaltyQ);
    const loyalties = this.dataHelper.docsToArray(loyaltyDocs);

    
    if (loyalties.length) {
      let docData = loyalties[0];
      
      if (docData.LoyaltyTypeItems__r?.records?.length) {
        loyaltyItems = docData.LoyaltyTypeItems__r.records?.filter(item => benefitsToSHow?.includes(item?.Type__c));
        loyaltyItems.sort((a, b) => {
          if (!a.Order__c) return 1;
          if (!b.Order__c) return -1;

          return a.Order__c < b.Order__c ? -1 : 1;
        });
      }
    }

    return loyaltyItems;
  };

  //pesquisa

  getUserSurveyAnswer = async function (callback) {
    let surveyQ = query(collection(this.db, 'Users', global.userFirebaseId, 'SurveyAnswersOption'));
    let unsub = onSnapshot(surveyQ, (snapshot) => {
      let surveys = this.dataHelper.getList(snapshot);
      callback(surveys);
    })
    return unsub;
  };

  getUserSurvey = async function (callback) {
    let surveyQ = query(collection(this.db, 'Survey'), where('Active__c', '==', true));
    let unsub = onSnapshot(surveyQ, (snapshot) => {
      let surveys = this.dataHelper.getList(snapshot);
      callback(surveys);
    })
    return unsub;
  };

  getCTGUser = async (callback) => {
    let campaignQ = query(collection(this.db, 'Users', global.userFirebaseId, 'CampaignMember'));

    let campaignQDocs = await getDocs(campaignQ)
    let foundUser = [];
    campaignQDocs.forEach((doc) => {
      foundUser.push(doc.data());
    })

    return foundUser;


    // let unsub = onSnapshot(campaignQ, (snapshot) => {
    //   let campaign = this.dataHelper.getList(snapshot);
    //   callback(campaign);
    // })
    //return unsub;
  }

  snapPointsByTransaction = (callback, idTransaction) => {
    let PointsTransactionQ = query(collection(this.db, 'Users', global.userFirebaseId, 'PointStatement'), where('acg_TransferStatement__r.Id', '==', idTransaction), orderBy('ExpirationDate__c', 'asc'));

    let unsub = onSnapshot(PointsTransactionQ, (snapshot) => {
      let points = this.dataHelper.getList(snapshot);
      callback(points);
    })

    return unsub;
  }

  async getPointGroupRulesHtml() {
    try {
      let html;

      let query = doc(this.db, 'AppSetting', 'RegraGrupoDePontos');
      let queryDoc = await getDoc(query);

      if (queryDoc.exists()) {
        html = queryDoc.data().HtmlValue__c
      }

      return html
    } catch (error) {
      return false
    }
  }

  async getCampaignsMiddlewareMapping() {
    try {
      let obj;

      let query = doc(this.db, 'AppSetting', 'CampaignsMiddlewareMapping');
      let queryDoc = await getDoc(query);

      if (queryDoc.exists()) {
        obj = queryDoc.data().TextValue__c
      }

      return obj
    } catch (error) {
      return false
    }
  }

  npsSendAnswer = async (
    survey,
    question,
    numberAnswer,
    textAnswer
  ) => {

    let question_ = question[0];
    let cgny2__Action__c = POINT_ACTIONS.ACTION_VOTE_SURVEY_ANSWER + 'NUMBER';
    //let rightAnswer = true;
    await GameficationHelper.addRow(
      this,
      cgny2__Action__c,
      question_.Id,        //cgny2__GenericId__c,
      undefined,           //cgny2__Event__c,
      textAnswer,          //cgny2__Value__c,
      undefined,           //cgny2__AdvertisingItem__c,
      undefined,           //cgny2__Gallery__c,
      undefined,           //cgny2__News__c,
      question_.Survey__c, //cgny2__Survey__c,
      undefined,           //cgny2__SurveyQuestionOption__c,
      question_.Id,        //cgny2__SurveyQuestion__c,
      undefined,           //cgny2__Voucher__c
      undefined,           //appUser
      undefined,           //recordType
      numberAnswer,        //cgny2__NumberValue__c
      undefined,           //cgny2__StartUpMessage__c
    );
  }

  npsSendClosure = async (survey) => {
    let surveyDoc = doc(this.db, 'Users', await this.getUserFirebaseId(), 'SurveyAnswers', survey.Id);

    setDoc(surveyDoc, {
      Id: survey.Id,
      actionDate: serverTimestamp()
    })

    const cgny2__Action__c = POINT_ACTIONS.ACTION_VOTE_SURVEY_COMPLETE;
    const cgny2__GenericId__c = survey.Id;

    GameficationHelper.addRow(
      this,
      cgny2__Action__c,
      cgny2__GenericId__c,
      undefined, //cgny2__Event__c,
      undefined, //cgny2__Value__c,
      undefined, //cgny2__AdvertisingItem__c,
      undefined, //cgny2__Gallery__c,
      undefined, //cgny2__News__c,
      survey.Id, //cgny2__Survey__c,
      undefined, //cgny2__SurveyQuestionOption__c,
      undefined, //cgny2__SurveyQuestion__c,
      undefined, //cgny2__Voucher__c
      undefined, //appUser
      undefined, //recordType
      undefined, //cgny2__NumberValue__c
      undefined, //cgny2__StartUpMessage__c
    );
  }

  startUpMessageHandle = async (startUpMessage, type) => {
    try {
      const response = await this.dataHelper.getRecordTypes('StartUpMessage');
      if (response.success) {
        let startUpMsgDoc = doc(this.db, 'Users', await this.getUserFirebaseId(), 'StartUpMessages', startUpMessage.Id);

        setDoc(startUpMsgDoc, {
          Id: startUpMessage.Id,
          actionDate: serverTimestamp()
        })

        const cgny2__Action__c = POINT_ACTIONS.ACTION_STARTUP_MESSAGE + type;
        const cgny2__GenericId__c = startUpMessage.Id;
        const cgny2__Value__c = type === 'CLICK' ? 'OPEN' : 'CLOSE';
        const recordType = response.recordTypes[0].Id;
        const cgny2__StartUpMessage__c = startUpMessage.Id;

        await GameficationHelper.addRow(
          this,
          cgny2__Action__c,
          cgny2__GenericId__c,
          undefined, //cgny2__Event__c,
          cgny2__Value__c,
          undefined, //cgny2__AdvertisingItem__c,
          undefined, //cgny2__Gallery__c,
          undefined, //cgny2__News__c,
          undefined, //cgny2__Survey__c,
          undefined, //cgny2__SurveyQuestionOption__c,
          undefined, //cgny2__SurveyQuestion__c,
          undefined, //cgny2__Voucher__c
          undefined, //appUser
          recordType,
          undefined, //cgny2__NumberValue__c
          cgny2__StartUpMessage__c
        );
      }
    } catch (error) {
      return {
        success: false,
        code: 'error-recordType',
      }
    }
  }

  getFinishedStartUpMessageIds = async () => {
    const finishedQ = collection(this.db, 'Users', await this.getUserFirebaseId(), 'StartUpMessages');
    const finishedDoc = await getDocs(finishedQ);
    const finishedIds = this.dataHelper.docsToArray(finishedDoc);

    let out = finishedIds.map((f) => f.Id);
    return out;
  }

  getPushNotifications = async () => {
    /*
    const notificationsDocs = await getDocs(
      query(
        collection(this.db, 'Users', global.userFirebaseId, 'PushNotification'),
        orderBy('CreatedDate', 'desc'),
      )
    );
    const notifications = [];

    notificationsDocs.forEach((doc) => {
      if (doc.exists() && !doc.data().Read__c && ["Group", "Transfer"].includes(doc.data().acg_Type__c)) {
        notifications.push({ ...doc.data(), ref: doc.ref });
      }
    });
    return notifications;
    */
  }

  markNotificationsAsRead = async (refs = []) => {
    await Promise.all(refs.map((ref) => {
      return updateDoc(ref, {
        Read__c: true
      });
    }));
  }

  getUserDocumentRef = async () => {
    let userFirebaseId = await this.getUserFirebaseId();

    if (!userFirebaseId) return null;
    return doc(this.db, COLLECTIONS.USERS, userFirebaseId);
  }
};
